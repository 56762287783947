<template>
    <div>
        <div class="header-row">
            <div class="logo" @click="$router.push('/')">
                <img style="height: 60px;" src="../assets/logo.png" alt="">
            </div>
            <div>
                <span class="homePage" style="margin-right: 50px;cursor: pointer;" @click="$router.push('/')">
                    主页
                </span>
                <span class="down" style="cursor:pointer;" @click="$router.push('/merchant')">
                    APP下载
                </span>
            </div>
        </div>
        <div class="leftFixed">
            <ul>
                <li>
                    <div class="row qqRow">
                        <img src="../assets/qq.png" alt="">
                        <div class="content-row qqContent">
                            <div class="content qqHover" @click="openPage">
                                <img src="../assets/qqhove.png" alt="">
                                <span >
                                   {{contactList.qq}}
                                </span>
                            </div>
                            <div class="trigon"></div>
                        </div>
                    </div>

                </li>
                <li>
                    <div class="row ifRow">
                        <img src="../assets/ifont.png" alt="">
                        <div class="content-row ifContent">
                            <div class="content">
                                <img src="../assets/ifoneHover.png" alt="">
                                <span>
                                    {{contactList.tel}}
                                </span>
                            </div>
                            <div class="trigon"></div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="row erRow">
                        <img src="../assets/erweima.png" alt="">
                        <div class="content-row erContent" >
                            <div class="content" style="display: flex;justify-content: center;align-items: center;padding: 20px 0">
                                <img style="width: 100px;height: 100px;" :src="preFix + contactList.weChat" alt="">
                            </div>
                            <div class="trigon"></div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <router-view></router-view>
        <footer class="footer">
            <a href="https://beian.miit.gov.cn">
                版权所有@2018-2025 带货平台系统软件系统提供商 苏ICP备2021039412号-1
            </a>
        </footer>
    </div>
</template>

<script>
    import api from '../lnit/api'
    export default {
        name: "index",
        data(){
            return{
                contactList:{},
                preFix:null,
            }
        },
        created() {
            this.preFix = api.url_prefix
          this.contactFn()
        },
        methods:{
            contactFn(){
                this.$get({
                    url:api.contact
                }).then((res)=>{
                    this.contactList = res.data.data
                })
            },
            openPage(){
                window.open('http://wpa.qq.com/msgrd?v=3&uin='+this.contactList.qq +'&site=在线客服&menu=yes')
            }
        }
    }
</script>

<style scoped lang="scss">
    .logo{
        cursor: pointer;
    }
    .homePage:hover{
        color: #888888;
    }
    .down:hover{
        color: #888888;
    }
    .header-row{
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 120px;
        box-sizing: border-box;
    }
    .leftFixed{
        position: fixed;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1000;
    }
    li{
        list-style: none;
    }
    .row{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 55px;
        height: 55px;
        background-color: #353535;
        margin-bottom: 10px;
        img{
            width: 22px;
            height: 22px;
        }
    }
    .qqRow:hover .qqContent{
        display: block;
    }
    .ifRow:hover .ifContent{
        display: block;
    }
    .erRow:hover .erContent{
        display: block;
    }
    .content-row{
        display: none;
        position: absolute;
        left: -15px;
        top: 50%;
        transform: translateY(-50%);
        padding: 20px;
        box-sizing: border-box;
        .content{
            display: flex;
            width: 150px;
            padding: 10px;
            box-sizing: border-box;
            background-color: #fff;
            position: absolute;
            left: -150px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
        }
    }
    .trigon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0px;
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-left: 10px solid white;
        border-bottom: 5px solid transparent;
    }

    .footer{
        background-color: #282828;
        /*width: 100vw;*/
        height: 60px;
        line-height: 60px;
        text-align: center;
        color: #fff;
        font-size: 12px;
        a{
            line-height: 60px;
            text-align: center;
            color: #fff;
            font-size: 12px;
            text-decoration: none;
        }
    }
</style>
